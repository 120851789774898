import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { EstablishmentMasters } from '../components';

const Responsables = () => {
  const masterType = MASTERS.PRODUCTIVA_TYPES.RESPONSABLE;
  const masterName = {
    CAPITALIZE: 'Responsable',
    LOWER: 'responsable',
    PLURAL: 'Responsables',
  };

  return (
    <EstablishmentMasters
      masterType={masterType}
      masterName={masterName}
      renderForm={(props) => <ResponsablesForm {...props} />}
      hideHeaderExtraActions={true}
      allowSinAsignar={true}
      actions={[GENERAL.ACTIONS.MODIFICAR, GENERAL.ACTIONS.ELIMINAR]}
    />
  );
};

export default Responsables;

const ResponsablesForm = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label="Nombre responsable"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre responsable' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};
