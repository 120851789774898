import { GENERAL, PRODUCTIVA, UNITS } from 'modules/core/constants';

const defaultColumns = [
  {
    title: 'Fecha',
    dataIndex: 'fecha',
    sorter: true,
    render: (d) => GENERAL.dateToFront(d),
  },
  {
    title: 'RP',
    dataIndex: 'unidad',
    sorter: true,
  },
  {
    title: 'Registro',
    dataIndex: 'registro',
    key: 'unidad__registro__nombre',
    sorter: true,
  },
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    render: (c) => UNITS.CATEGORIES_FORMATTED[c],
  },
];

const actionsColumn = (renderActions) => [
  {
    title: 'Acciones',
    align: 'right ',
    render: (_, record) => renderActions?.(record),
  },
];

const COLUMNS = {};

// UNIDADES
COLUMNS[PRODUCTIVA.BAJA] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Motivo',
        dataIndex: 'motivo',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.MOVIMIENTO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Ubicación inicial',
        dataIndex: 'inicial_ubicacion',
        sorter: true,
      },
      {
        title: 'Ubicación final',
        dataIndex: 'final_ubicacion',
        sorter: true,
      },
      {
        title: 'Rodeo inicial',
        dataIndex: 'inicial_rodeo',
        sorter: true,
      },
      {
        title: 'Rodeo final',
        dataIndex: 'final_rodeo',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

// PRODUCTIVA
COLUMNS[PRODUCTIVA.DESTETE] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Clasificación al destete',
        dataIndex: 'objetivo',
        sorter: true,
      },
      {
        title: 'Peso al nacimiento',
        dataIndex: 'peso_nacimiento',
        key: 'unidad__peso_nacimiento',
        sorter: true,
        render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
      },
      {
        title: 'Peso del destete',
        dataIndex: 'peso',
        sorter: true,
        render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
      },
      {
        title: 'Ganancia diaria',
        dataIndex: 'ganancia_diaria',
        render: (g) => GENERAL.numToFront(g, 2),
      },
      {
        title: 'Comparación con peso promedio destete',
        dataIndex: 'comparacion_peso_promedio_destete',
        render: (w) => (Math.round(w) || 0) + '%',
      },
      {
        title: 'Kilos destetados',
        dataIndex: 'kilos_destetados',
        sorter: true,
        render: (w) => (Math.round(w) || 0) + '%',
      },
      {
        title: 'Cantidad de días transcurridos',
        dataIndex: 'dias_desde_fecha_nacimiento',
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.TACTO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Resultado del tacto',
        dataIndex: 'apta_reproduccion',
        sorter: true,
        render: (r) =>
          GENERAL.optionToLabel(PRODUCTIVA.OPTIONS[PRODUCTIVA.TACTO], r),
      },
      {
        title: 'Condición Corporal',
        dataIndex: 'condicion_corporal',
        sorter: true,
        render: (v) => GENERAL.numToFront(v, 2),
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.ASIGNACION] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Apodo padre',
        dataIndex: 'apodo_padre',
        key: 'unidad__padre__apodo',
        sorter: true,
      },
      {
        title: 'Machos asignados',
        dataIndex: 'machos',
        render: (ms, { apodo_macho }) => {
          const mn = apodo_macho.split(',');
          return ms
            .split(',')
            .map((m, i) => `${m} ${mn[i]}`)
            .join(', ');
        },
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.SERVICIO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Macho',
        dataIndex: 'macho',
        key: 'macho__rp',
        sorter: true,
        render: (m, { apodo_macho }) => `${m} ${apodo_macho}`,
      },
      {
        title: 'Tipo',
        dataIndex: 'tipo',
        sorter: true,
      },
      {
        title: 'Decrementó stock',
        dataIndex: 'decrementar_stock',
        sorter: true,
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.SERVICIOCOLECTIVO] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha de inicio',
        dataIndex: 'fecha_inicio',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Machos',
        dataIndex: 'machos',
        key: 'machos__rp',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.TRANSFERENCIAEMBRIONARIA] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Embrión',
        dataIndex: 'embrion',
        sorter: true,
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable_value',
        key: 'responsable__nombre',
        sorter: true,
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.PRENIEZ] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Tipo de embrión',
        dataIndex: 'tipo',
        sorter: true,
      },
      {
        title: 'Toro de servicio',
        dataIndex: 'toro_servicio',
      },
      {
        title: 'Resultado de preñez',
        dataIndex: 'ok',
        sorter: true,
        render: (r) =>
          GENERAL.optionToLabel(
            PRODUCTIVA.OPTIONS[PRODUCTIVA.PRENIEZ].STATUS,
            r
          ),
      },
      {
        title: 'Fecha último servicio',
        dataIndex: 'fecha_ultimo_servicio',
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Días de Preñada',
        dataIndex: 'cant_dias_prenez',
      },
      {
        title: 'Fecha probable de parto',
        dataIndex: 'fecha_probable_parto',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'Días transcurridos desde FPP',
        dataIndex: 'dias_desde_fecha_probable_parto',
      },
      {
        title: 'Condición Corporal',
        dataIndex: 'condicion_corporal',
        sorter: true,
        render: (v) => GENERAL.numToFront(v, 2),
      },
      {
        title: 'CUT',
        dataIndex: 'cut',
        sorter: true,
        render: (c) => (c ? 'Sí' : 'No'),
      },
      {
        title: 'Preñada por servicio individual',
        dataIndex: 'prenada_por_servicio',
        sorter: true,
        render: (c) => (c ? 'Sí' : '-'),
      },
    ])
    .concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.PARTO] = (actions, resume) =>
  [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'RP',
      dataIndex: 'unidad',
      sorter: true,
    },
    {
      title: 'IEP',
      dataIndex: 'iep',
      sorter: true,
    },
    {
      title: 'Tipo de parto',
      dataIndex: 'tipo',
      sorter: true,
    },
    {
      title: 'Días de gestación',
      dataIndex: 'dias_gestacion',
      sorter: true,
    },
    {
      title: 'Crías',
      dataIndex: 'hijos',
      render: (hs) => hs?.join(', '),
    },
    {
      title: 'RP Padre',
      dataIndex: 'rp_padre',
      key: 'padre__rp',
      sorter: true,
    },
    {
      title: 'Apodo macho',
      dataIndex: 'apodo_macho',
      key: 'unidad__padre__apodo',
      sorter: true,
    },
    {
      title: 'Peso nacimiento',
      dataIndex: 'peso_nacimiento',
      key: 'unidad__peso_nacimiento',
      sorter: true,
      render: (ws) => ws?.map((w) => (w ? `${w}kg` : '-')).join(', '),
    },
    {
      title: 'Condición Corporal',
      dataIndex: 'condicion_corporal',
      sorter: true,
      render: (v) => GENERAL.numToFront(v, 2),
    },
    {
      align: 'right',
      render: (_, parto) => (parto.completado ? null : resume(parto)),
    },
  ].concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.PESAJE] = (actions) =>
  [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'RP',
      dataIndex: 'unidad',
      sorter: true,
    },
    {
      title: 'Nacimiento',
      dataIndex: 'fecha_nacimiento',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'Categoría',
      dataIndex: 'categoria',
      render: (c) => UNITS.CATEGORIES_FORMATTED[c],
    },
    {
      title: 'Peso anterior',
      dataIndex: 'peso_previo',
      sorter: true,
      render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
    },
    {
      title: 'Peso actualizado',
      dataIndex: 'peso',
      sorter: true,
      render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
    },
    {
      title: 'Ganancia Diaria Variable',
      dataIndex: 'ganancia_diaria',
      sorter: true,
      render: (g) => GENERAL.numToFront(g, 2),
    },
    {
      title: 'Ganancia Diaria Total',
      dataIndex: 'ganancia_diaria_total',
      sorter: true,
      render: (g) => GENERAL.numToFront(g, 2),
    },
  ].concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.SELECCION_HEMBRAS] = (actions) =>
  [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'RP',
      dataIndex: 'unidad',
      sorter: true,
    },
    {
      title: 'Apodo padre',
      dataIndex: 'apodo_padre',
      key: 'unidad__padre__apodo',
      sorter: true,
    },
    {
      title: 'Apodo abuelo materno',
      dataIndex: 'apodo_abuelo_materno',
    },
    {
      title: 'Peso al destete',
      dataIndex: 'peso_destete',
      render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
    },
    {
      title: 'Selección de categoría',
      dataIndex: 'categoria',
      render: (c) => UNITS.CATEGORIES_FORMATTED[c],
    },
  ].concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.SELECCION_MACHOS] = (actions) =>
  [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'RP',
      dataIndex: 'unidad',
      sorter: true,
    },
    {
      title: 'Apodo padre',
      dataIndex: 'apodo_padre',
      key: 'unidad__padre__apodo',
      sorter: true,
    },
    {
      title: 'Apodo abuelo materno',
      dataIndex: 'apodo_abuelo_materno',
    },
    {
      title: 'Peso al destete',
      dataIndex: 'peso_destete',
      render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
    },
    {
      title: 'Selección de categoría',
      dataIndex: 'categoria',
      render: (c) => UNITS.CATEGORIES_FORMATTED[c],
    },
  ].concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.DEPS] = (actions) =>
  [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'RP',
      dataIndex: 'unidad',
      sorter: true,
    },
    {
      title: 'DEP',
      dataIndex: 'dep',
      sorter: true,
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      sorter: true,
    },
  ].concat(actionsColumn(actions));

COLUMNS[PRODUCTIVA.DATOS_ADICIONALES] = (actions) =>
  [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      sorter: true,
      render: (d) => GENERAL.dateToFront(d),
    },
    {
      title: 'RP',
      dataIndex: 'unidad',
      sorter: true,
    },
    {
      title: 'Dato adicional',
      dataIndex: 'dato_adicional',
      sorter: true,
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      sorter: true,
    },
  ].concat(actionsColumn(actions));

// OVINOS
COLUMNS[PRODUCTIVA.ESQUILA] = (actions) =>
  defaultColumns
    .concat([
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        sorter: true,
        render: (d) => GENERAL.dateToFront(d),
      },
      {
        title: 'RP',
        dataIndex: 'unidad',
        sorter: true,
      },
      {
        title: 'Peso de lana producida',
        dataIndex: 'peso_lana',
        sorter: true,
        render: (w) => w && `${w} kg`,
      },
      {
        title: 'Calidad del vellón',
        dataIndex: 'calidad_vellon',
        sorter: true,
        render: (c) => c && `${c} µm`,
      },
    ])
    .concat(actionsColumn(actions));

export { COLUMNS };
