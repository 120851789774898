import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import _ from 'lodash';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import { withAPI } from 'modules/api';
import { GENERAL, UNITS } from 'modules/core/constants';
import { ModalTitle } from 'modules/core/components';
import './EstablishmentDrawerAdd.scss';

const { Item: FormItem } = Form;

const title = (editing) => {
  return editing ? (
    <ModalTitle icon={<EditOutlined />} title="Modificar establecimiento" />
  ) : (
    <ModalTitle icon={<FileAddOutlined />} title="Agregar establecimiento" />
  );
};

const EstablishmentDrawerAdd = ({
  api,
  editEst = null,
  isEdit = false,
  open,
  onClose,
  onSuccess,
}) => {
  const [form] = Form.useForm();

  const { mutate: create, isLoading: creatingEst } = useMutation(
    api.createEstablishment
  );
  const { mutate: edit, isLoading: editingEst } = useMutation(
    api.editEstablishment
  );

  const clearForm = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(clearForm, [clearForm, editEst]);

  const handleOnCancel = () => {
    clearForm();
    onClose && onClose();
  };
  const handleOnSuccess = () => {
    clearForm();
    onSuccess && onSuccess();
  };

  const onFinish = (values) => {
    const formattedEst = _.mapValues(values, (v) => v ?? null);
    const onError = ({ message, description }) => {
      if (message === '400') message.error(description);
      else message.error('ERROR');
    };
    const showDisplay = ({ isEdit = false }) => {
      handleOnSuccess();
      message.success(
        `Se ${isEdit ? 'modificó' : 'agregó'} el establecimiento con éxito`
      );
    };
    if (editEst) {
      const onSuccess = () => showDisplay({ isEdit: true });
      edit({ estId: editEst.id, est: formattedEst }, { onSuccess, onError });
    } else {
      const onSuccess = () => showDisplay({ isEdit: false });
      create({ est: formattedEst }, { onSuccess, onError });
    }
  };

  return (
    <Drawer
      title={title(isEdit)}
      forceRender
      className="est-add-drawer"
      placement="right"
      width="40%"
      onClose={handleOnCancel}
      open={open}
      closable={false}
    >
      <div className="est-add-form-wrapper">
        <Form
          form={form}
          className="est-add-form"
          layout="vertical"
          requiredMark={false}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          initialValues={editEst}
        >
          <Row gutter={16}>
            <Col span="12">
              <FormItem
                label="Nombre del establecimiento"
                name="name"
                rules={
                  editEst
                    ? [
                        {
                          required: true,
                          message: 'Ingrese el nombre del establecimiento',
                        },
                      ]
                    : [
                        {
                          required: true,
                          type: 'string',
                          pattern: /^[a-z0-9]+$/,
                          message:
                            'Ingrese el nombre del establecimiento (Sólo minúsculas y números).',
                        },
                      ]
                }
              >
                <Input className="est-add-form-input" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                label="Especie"
                name="type"
                rules={[{ required: true, message: 'Seleccione una especie' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  disabled={editEst}
                  options={GENERAL.arrayToOptions(
                    UNITS.ESPECIES_LIST,
                    (e) => UNITS.ESPECIES_FORMATTED[e]
                  )}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span="12">
              <FormItem
                label="Dirección legal"
                name="direccion_legal"
                rules={[{ required: false, message: 'Ingrese dirección' }]}
              >
                <Input className="est-add-form-input" />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Latitud"
                name="lat"
                rules={[{ required: false, message: 'Ingrese latitud' }]}
              >
                <InputNumber className="est-add-form-input" />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Longitud"
                name="lon"
                rules={[{ required: false, message: 'Ingrese longitud' }]}
              >
                <InputNumber className="est-add-form-input" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className="est-add-form-footer">
            <Col span="12">
              <FormItem>
                <Button
                  htmlType="button"
                  className="est-add-form-button"
                  onClick={handleOnCancel}
                >
                  {editEst ? 'VOLVER' : 'CANCELAR'}
                </Button>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="est-add-form-button"
                  loading={creatingEst || editingEst}
                >
                  {editEst ? 'MODIFICAR' : 'AGREGAR'}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    </Drawer>
  );
};

export default withAPI(EstablishmentDrawerAdd);
