import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MANAGEMENT, NUTRICIONAL } from 'modules/core/constants';
import Dashboard from './Dashboard';
import { Dieta, RecordHistory } from './events';
import { Suplementos } from './noevents';
import { Adquisiciones } from '../components';

const BASE = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.NUTRICIONAL}`;
const eventRecordTypes = NUTRICIONAL.EVENT_TYPES;
const noEventTypes = NUTRICIONAL.NOEVENT_TYPES_LIST.join('|');

const Routes = () => {
  return (
    <Switch>
      <Route exact path={BASE} component={Dashboard} />
      <Route exact path={`${BASE}/${NUTRICIONAL.DIETA}`} component={Dieta} />
      <Route
        exact
        path={`${BASE}/:eventType(${eventRecordTypes})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={RecordHistory}
      />
      <Route
        exact
        path={`${BASE}/${NUTRICIONAL.SUPLEMENTO}`}
        component={Suplementos}
      />
      <Route
        exact
        path={`${BASE}/:noEventType(${noEventTypes})/${MANAGEMENT.ADQUISICIONES_NAME_SPACE}`}
        component={Adquisiciones}
      />
      <Redirect to={BASE} />
    </Switch>
  );
};

export default Routes;
