import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  List,
  Select,
  Space,
  Alert,
  Tooltip,
  message as antMessage,
  Spin,
} from 'antd';
import { MANAGEMENT, MASTERS, ECONOMICA } from 'modules/core/constants';
import { Card, ScreenHeader, Title } from 'modules/core/components';

import './Dashboard.scss';

const BASE_URL = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`;

const Dashboard = ({ api }) => {
  let history = useHistory();

  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      { masterTypes: [MASTERS.ECONOMICA_TYPES.DIVISA] },
    ],
    api.fetchMastersFormOptions
  );

  const {
    data: actualCurrency,
    isLoading,
    refetch,
  } = useQuery(['Economica::fetchDivisaActual'], api.fetchDivisaActual);

  const { mutate: editDivisaActual, isLoading: isEditingDivisaActual } =
    useMutation(api.editDivisaActual, {
      onError: ({ message, description }) => {
        if (message === '400') antMessage.error(description);
        else antMessage.error('ERROR');
      },
      onSuccess: () => {
        refetch();
        antMessage.success('Divisa actualizada.');
      },
    });

  const renderCurrency = () => (
    <Space>
      <Title text="Divisa" level={2} />
      <Select
        placeholder="Seleccionar"
        style={{ width: 120 }}
        value={actualCurrency?.currency}
        disabled={
          !actualCurrency?.editable || isLoadingOptions || isEditingDivisaActual
        }
        loading={isLoadingOptions || isEditingDivisaActual}
        options={options?.[MASTERS.ECONOMICA_TYPES.DIVISA]}
        onChange={(value) => editDivisaActual({ currency: value })}
      />
      <Tooltip
        placement="topRight"
        title="Solo se permite modificar la divisa si no hay transacciones realizadas."
      >
        <InfoCircleOutlined />
      </Tooltip>
    </Space>
  );

  const renderCurrencyAlert = () => (
    <Col span={24}>
      <Alert
        type="warning"
        message="Recordá configurar la divisa para utilizar el módulo de gestión económica."
      />
    </Col>
  );

  const renderEvents = ({ key = null, title = '', events = [] }) => (
    <Col span={24} key={key}>
      <Col span={24}>
        <Title className="section-title" text={title} level={2} />
      </Col>
      <Col span={24}>
        <List
          dataSource={events}
          grid={{
            gutter: 24,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          renderItem={({ title, url }) => (
            <List.Item>
              <Card
                title={title}
                className="event-card"
                {...(actualCurrency?.currency !== null && {
                  showGoToIcon: true,
                  onClick: () => history.push(`${BASE_URL}/${url}`),
                })}
              />
            </List.Item>
          )}
        />
      </Col>
    </Col>
  );

  if (isLoading) return <Spin />;

  return (
    <Row gutter={[16, 16]} className="management-economica-dashboard">
      <Col span={24}>
        <ScreenHeader
          title="Gestión económica"
          rightActions={renderCurrency()}
        />
      </Col>
      {!actualCurrency?.currency && renderCurrencyAlert()}
      {_.map(ECONOMICA.EVENT_DASHBOARD, ({ title, items }, ix) =>
        renderEvents({
          key: ix,
          title,
          events: _.map(items, (el) => el),
        })
      )}
    </Row>
  );
};

export default withAPI(Dashboard);
