import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MANAGEMENT, ECONOMICA } from 'modules/core/constants';
import Dashboard from './Dashboard';
import {
  CompraInsumos,
  CompraUnidad,
  OtrosEgresos,
  OtrosIngresos,
  VentaUnidad,
  RecordHistory,
} from './events';

const BASE = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`;
const eventRecordTypes = [
  ECONOMICA.INGRESOS_VENTA_UNIDAD,
  ECONOMICA.EGRESO_COMPRA_UNIDAD,
].join('|');

const Routes = () => {
  return (
    <Switch>
      <Route exact path={BASE} component={Dashboard} />
      <Route
        exact
        path={`${BASE}/${ECONOMICA.INGRESOS_VENTA_UNIDAD}`}
        component={VentaUnidad}
      />
      <Route
        exact
        path={`${BASE}/${ECONOMICA.INGRESOS_OTROS}`}
        component={OtrosIngresos}
      />
      <Route
        exact
        path={`${BASE}/${ECONOMICA.EGRESO_COMPRA_UNIDAD}`}
        component={CompraUnidad}
      />
      <Route
        exact
        path={`${BASE}/${ECONOMICA.EGRESO_OTROS}`}
        component={OtrosEgresos}
      />
      <Route
        path={`${BASE}/${ECONOMICA.EGRESO_COMPRA_INSUMOS}`}
        component={CompraInsumos}
      />
      <Route
        exact
        path={`${BASE}/:eventType(${eventRecordTypes})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={RecordHistory}
      />
      <Redirect to={BASE} />
    </Switch>
  );
};

export default Routes;
