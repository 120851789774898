import React from 'react';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { EstablishmentMasters } from '../components';
import UbicacionesForm from './UbicacionesForm';

const UbicacionesList = () => {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      title: 'Superficie',
      dataIndex: 'superficie_ganadera',
      render: (a) => a && GENERAL.numToFront(a, 2, ' ha'),
    },
    {
      title: 'Carga animal por unidad',
      dataIndex: 'carga_animal_unidad',
      render: (w) => GENERAL.numToFront(w, 2),
    },
    {
      title: 'Peso total',
      dataIndex: 'peso_total',
      render: (w) => w && GENERAL.numToFront(w, 2, ' kg'),
    },
    {
      title: 'Porcentaje de peso',
      dataIndex: 'porcentaje_peso',
      render: (w) => (w === '-' ? w : `${w}%`),
    },
    {
      title: 'Carga animal por peso',
      dataIndex: 'carga_animal_peso',
      render: (w) => GENERAL.numToFront(w, 2),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
    },
  ];

  const masterType = MASTERS.PRODUCTIVA_TYPES.UBICACION;
  const masterName = {
    CAPITALIZE: 'Ubicación',
    LOWER: 'ubicación',
    PLURAL: 'Ubicaciones',
  };

  return (
    <EstablishmentMasters
      masterType={masterType}
      masterName={masterName}
      columns={columns}
      initialValues={(v) => ({
        is_padre: false,
        ...v,
        ...(v?.is_padre && {
          ubicacion_padre: null,
          superficie_ganadera: null,
        }),
      })}
      renderForm={(props) => <UbicacionesForm {...props} />}
    />
  );
};

export default UbicacionesList;
