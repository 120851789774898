import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withSubMenuLayout } from 'modules/core/layouts';
import { ESTABLISHMENT, GENERAL } from 'modules/core/constants';
import Criadores from './Criadores/Criadores';
import Planificacion from './Planificacion/Planificacion';
import Responsables from './Responsables/Responsables';
import Rodeos from './Rodeos/Rodeos';
import RodeosDeactivatedList from './Rodeos/RodeosDeactivatedList';
import Tareas from './Tareas/Tareas';
import Ubicaciones from './Ubicaciones/Ubicaciones';
import UbicacionesDeactivatedList from './Ubicaciones/UbicacionesDeactivatedList';

const submenuOptions = [
  {
    title: 'Planificación',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`,
    exact: false,
  },
  {
    title: 'Ubicaciones',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.UBICACIONES}`,
    exact: false,
  },
  {
    title: 'Rodeos',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RODEOS}`,
    exact: false,
  },
  {
    title: 'Criadores / Propietarios',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.CRIADORES}`,
    exact: false,
  },
  {
    title: 'Responsables',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RESPONSABLES}`,
    exact: false,
  },
  {
    title: 'Tareas',
    url: `/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.TAREAS}`,
    exact: false,
  },
];
const withSubLayout = withSubMenuLayout(submenuOptions);

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`}
        component={withSubLayout(Planificacion)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.CRIADORES}`}
        component={withSubLayout(Criadores)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RESPONSABLES}`}
        component={withSubLayout(Responsables)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RODEOS}`}
        component={withSubLayout(Rodeos)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.RODEOS}/${GENERAL.DEACTIVE}`}
        component={withSubLayout(RodeosDeactivatedList)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.UBICACIONES}`}
        component={withSubLayout(Ubicaciones)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.UBICACIONES}/${GENERAL.DEACTIVE}`}
        component={withSubLayout(UbicacionesDeactivatedList)}
      />
      <Route
        exact
        path={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.TAREAS}`}
        component={withSubLayout(Tareas)}
      />
      <Redirect
        to={`/${ESTABLISHMENT.NAME_SPACE}/${ESTABLISHMENT.PLANIFICACION}`}
      />
    </Switch>
  );
};

export default Routes;
