import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withSubMenuLayout } from 'modules/core/layouts';
import { MANAGEMENT, UNITS } from 'modules/core/constants';
import UnidadesAnalytics from './UnidadesAnalytics/UnidadesAnalytics';
import ProductivaAnalytics from './ProductivaAnalytics/ProductivaAnalytics';

const PATH_BASE = 'analiticas';
const submenuOptions = [
  {
    title: 'Unidades',
    url: `/${PATH_BASE}/${UNITS.NAME_SPACE}`,
    exact: false,
  },
  {
    title: 'Productiva',
    url: `/${PATH_BASE}/${MANAGEMENT.PRODUCTIVA}`,
    exact: false,
  },
];

const withSubLayout = withSubMenuLayout(submenuOptions);
const Routes = () => {
  return (
    <Switch>
      <Route
        path={`/${PATH_BASE}/${UNITS.NAME_SPACE}`}
        component={withSubLayout(UnidadesAnalytics)}
      />
      <Route
        path={`/${PATH_BASE}/${MANAGEMENT.PRODUCTIVA}`}
        component={withSubLayout(ProductivaAnalytics)}
      />
      <Redirect to={`/${PATH_BASE}/${UNITS.NAME_SPACE}`} />
    </Switch>
  );
};

export default Routes;
