import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { GENERAL, UNITS } from 'modules/core/constants';
import './EstablishmentInfoCard.scss';

const EstablishmentInfoCard = ({ data, onEdit = null }) => {
  const [lat, lon] = GENERAL.dec2deg(data.lat, data.lon);
  return (
    <div className="est-info-card">
      <div className="est-info-action">
        <Button
          type="text"
          onClick={onEdit}
          icon={<EditOutlined className="action-icon" />}
        />
      </div>
      <div className="est-info-field">
        <div className="est-info-title">Nombre del establecimiento</div>
        <div className="est-info-text">{data.name || '-'}</div>
      </div>
      <div className="est-info-field">
        <div className="est-info-title">Especie</div>
        <div className="est-info-text">
          {UNITS.ESPECIES_FORMATTED[data.type] || '-'}
        </div>
      </div>
      <div className="est-info-field">
        <div className="est-info-title">Dirección legal</div>
        <div className="est-info-text">{data.direccion_legal || '-'}</div>
      </div>
      <div className="est-info-field">
        <div className="est-info-title">Dirección geográfica</div>
        <div className="est-info-text">
          {data.lat ? lat : '-'} / {data.lon ? lon : '-'}
        </div>
      </div>
    </div>
  );
};

export default EstablishmentInfoCard;
