import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { MASTERS } from 'modules/core/constants';
import { EstablishmentMasters } from '../components';

const Rodeos = () => {
  const masterType = MASTERS.PRODUCTIVA_TYPES.RODEO;
  const masterName = {
    CAPITALIZE: 'Rodeo',
    LOWER: 'rodeo',
    PLURAL: 'Rodeos',
  };

  return (
    <EstablishmentMasters
      masterType={masterType}
      masterName={masterName}
      renderForm={(props) => <RodeoForm {...props} />}
    />
  );
};

export default Rodeos;

const RodeoForm = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label="Nombre del rodeo"
          name="nombre"
          rules={[{ required: true, message: 'Ingrese nombre de rodeo' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Descripción del rodeo" name="descripcion">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Col>
    </Row>
  );
};
