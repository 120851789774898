import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withSubMenuLayout } from 'modules/core/layouts';
import { MANAGEMENT } from 'modules/core/constants';
import Economica from './economica';
import Nutricional from './nutricional';
import Productiva from './productiva';
import Sanitaria from './sanitaria';

const submenuOptions = [
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.PRODUCTIVA],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`,
    exact: false,
  },
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.SANITARIA],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.SANITARIA}`,
    exact: false,
  },
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.NUTRICIONAL],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.NUTRICIONAL}`,
    exact: false,
  },
  {
    title: MANAGEMENT.MANAGEMENT_FORMATTED[MANAGEMENT.ECONOMICA],
    url: `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`,
    exact: false,
  },
];
const withSubLayout = withSubMenuLayout(submenuOptions);
const Routes = () => {
  return (
    <Switch>
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`}
        component={withSubLayout(Productiva)}
      />
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.SANITARIA}`}
        component={withSubLayout(Sanitaria)}
      />
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.NUTRICIONAL}`}
        component={withSubLayout(Nutricional)}
      />
      <Route
        path={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.ECONOMICA}`}
        component={withSubLayout(Economica)}
      />
      <Redirect to={`/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`} />
    </Switch>
  );
};

export default Routes;
