import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MANAGEMENT, PRODUCTIVA } from 'modules/core/constants';
import Dashboard from './Dashboard';
import {
  Asignacion,
  DatosAdicionales,
  DEPs,
  Destete,
  Parto,
  Pesaje,
  Preniez,
  SeleccionHembras,
  SeleccionMachos,
  Tacto,
  Servicios,
  Esquila,
  RecordHistory,
} from './events';
import {
  ClasificacionAlDestete,
  Embrion,
  MotivosBaja,
  Semen,
} from './noevents';
import { Adquisiciones } from '../components';

const BASE = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.PRODUCTIVA}`;
const eventTypes = PRODUCTIVA.EVENT_TYPES.filter(
  (item) => !PRODUCTIVA.EVENT_TYPES_UNIT_ROUTES.includes(item)
).join('|');
const noEventTypes = PRODUCTIVA.NOEVENT_TYPES_LIST.join('|');

const Routes = () => {
  return (
    <Switch>
      <Route exact path={BASE} component={Dashboard} />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.ASIGNACION}`}
        component={Asignacion}
      />
      <Route exact path={`${BASE}/${PRODUCTIVA.DESTETE}`} component={Destete} />
      <Route exact path={`${BASE}/${PRODUCTIVA.PARTO}`} component={Parto} />
      <Route exact path={`${BASE}/${PRODUCTIVA.PESAJE}`} component={Pesaje} />
      <Route exact path={`${BASE}/${PRODUCTIVA.PRENIEZ}`} component={Preniez} />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.DATOS_ADICIONALES}`}
        component={DatosAdicionales}
      />
      <Route exact path={`${BASE}/${PRODUCTIVA.DEPS}`} component={DEPs} />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.SELECCION_HEMBRAS}`}
        component={SeleccionHembras}
      />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.SELECCION_MACHOS}`}
        component={SeleccionMachos}
      />
      <Route exact path={`${BASE}/${PRODUCTIVA.TACTO}`} component={Tacto} />
      <Route
        path={`${BASE}/${PRODUCTIVA.SERVICIOS.NAME_SPACE}`}
        component={Servicios}
      />
      <Route exact path={`${BASE}/${PRODUCTIVA.ESQUILA}`} component={Esquila} />
      <Route
        exact
        path={`${BASE}/:eventType(${eventTypes})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={RecordHistory}
      />
      <Route exact path={`${BASE}/${PRODUCTIVA.EMBRION}`} component={Embrion} />
      <Route exact path={`${BASE}/${PRODUCTIVA.SEMEN}`} component={Semen} />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.MOTIVOS_BAJA}`}
        component={MotivosBaja}
      />
      <Route
        exact
        path={`${BASE}/${PRODUCTIVA.CLASIFICACION_DESTETE}`}
        component={ClasificacionAlDestete}
      />
      <Route
        exact
        path={`${BASE}/:noEventType(${noEventTypes})/${MANAGEMENT.ADQUISICIONES_NAME_SPACE}`}
        component={Adquisiciones}
      />
      <Redirect to={BASE} />
    </Switch>
  );
};

export default Routes;
