import React from 'react';
import { Row, Col, Form, Input, InputNumber } from 'antd';
import { ChangePasswordModal } from '../';

const { Item: FormItem } = Form;

const SectionTitle = ({ title }) => (
  <Row>
    <Col span={24} className="form-title">
      {title}
    </Col>
  </Row>
);

const ProfileForm = ({ form, user, barn }) => {
  return (
    <Form
      form={form}
      initialValues={{
        first_name: user.first_name,
        ...barn[0],
      }}
      layout="vertical"
      requiredMark={false}
    >
      <SectionTitle title="Mi usuario" />
      <Row gutter={16}>
        <Col span={12}>
          <FormItem
            label="Nombre"
            name="first_name"
            rules={[{ required: true, message: 'Ingrese nombre' }]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Contraseña"
            name="password"
            initialValue="***********"
          >
            <Input.Password disabled={true} visibilityToggle={false} />
          </FormItem>
          <div className="change-password">
            <ChangePasswordModal />
          </div>
        </Col>
      </Row>
      <SectionTitle title="Mi establecimiento" />
      <Row gutter={16}>
        <Col span={24}>
          <FormItem
            label="Nombre"
            name="name"
            rules={[{ required: true, message: 'Ingrese nombre' }]}
          >
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem
            label="Dirección legal"
            name="direccion_legal"
            rules={[{ required: true, message: 'Ingrese dirección legal' }]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            label="Latitud"
            name="lat"
            rules={[{ required: true, message: 'Ingrese latitud' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            label="Longitud"
            name="lon"
            rules={[
              {
                required: true,
                message: 'Ingrese longitud',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfileForm;
