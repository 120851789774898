import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { APIProvider, FincaAPI } from 'modules/api';
import {
  ESTABLISHMENT,
  GENERAL,
  MANAGEMENT,
  UNITS,
  USERS,
} from 'modules/core/constants';
import ThemeSelector from 'modules/core/theme/ThemeSelector';
import PrivateRoute from './PrivateRoute';
import Admin from 'modules/admin';
import Analytics from 'modules/analytics';
import Establishment from 'modules/establishment';
import Home from 'modules/home/Home';
import Login from 'modules/login/Login';
import Management from 'modules/management';
import MobileSync from 'modules/mobileSync/MobileSync';
import Notifications from 'modules/notifications/Notifications';
import Profile from 'modules/profile/Profile';
import Units from 'modules/units';

import 'antd/dist/antd.min.css';

moment.locale('es');
const authAPI = FincaAPI.withAuthUser(GENERAL.LOCAL_TOKEN.get());
const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider locale={esES}>
      <QueryClientProvider client={queryClient}>
        <APIProvider value={{ api: authAPI }}>
          <ThemeSelector>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PrivateRoute
                path="/admin"
                component={Admin}
                allowedRoles={[USERS.ROLES.ADMIN_USER]}
              />
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute
                exact
                path="/perfil"
                component={Profile}
                allowedRoles={[USERS.ROLES.FINCA_USER]}
              />
              <PrivateRoute
                exact
                path="/sincronizacion"
                component={MobileSync}
                allowedRoles={[USERS.ROLES.FINCA_USER]}
              />
              <PrivateRoute
                exact
                path="/notificaciones"
                component={Notifications}
                allowedRoles={[USERS.ROLES.FINCA_USER]}
              />
              <PrivateRoute
                path={`/${ESTABLISHMENT.NAME_SPACE}`}
                component={Establishment}
              />
              <PrivateRoute path={`/${UNITS.NAME_SPACE}`} component={Units} />
              <PrivateRoute
                path={`/${MANAGEMENT.NAME_SPACE}`}
                component={Management}
              />
              <PrivateRoute path="/analiticas" component={Analytics} />
              {/* Paginas de estados / errores */}
              <Route component={() => <h1>Finca - Content not found</h1>} />
            </Switch>
          </ThemeSelector>
        </APIProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
