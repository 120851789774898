import React from 'react';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';
import { MANAGEMENT, NUTRICIONAL } from 'modules/core/constants';
import { ManagementDashboard } from 'modules/management/components';
import _ from 'lodash';

const BASE_URL = `/${MANAGEMENT.NAME_SPACE}/${MANAGEMENT.NUTRICIONAL}`;

const Dashboard = ({ api }) => {
  const { data, isLoading } = useQuery(
    ['Units::fetchKPIEvents'],
    api.fetchKPIEvents
  );
  const events =
    data &&
    _.map(NUTRICIONAL.EVENT_DASHBOARD, (card, event) => ({
      ...card,
      quantity: data[event] ?? 0,
      url: `${BASE_URL}/${card.url}`,
    }));

  return (
    <ManagementDashboard
      title="Gestión nutricional"
      loading={isLoading}
      events={events}
      noEvents={[
        {
          title: 'Insumos',
          data: _.map(NUTRICIONAL.NOEVENTS_DASHBOARD_INSUMOS, (el) => ({
            ...el,
            url: `${BASE_URL}/${el.url}`,
          })),
        },
      ]}
    />
  );
};

export default withAPI(Dashboard);
