import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withAPI } from 'modules/api';
import { USERS } from 'modules/core/constants';
import { AppLayout } from 'modules/core/layouts';

const DEFAULT_REDIRECT = {
  [USERS.ROLES.ADMIN_USER]: '/admin',
  [USERS.ROLES.FINCA_USER]: '/',
};

const PrivateRoute = withAPI(
  ({
    component: Component,
    api,
    allowedRoles = [USERS.ROLES.FINCA_USER],
    ...rest
  }) => {
    const getRoleComponent = ({ userRole, props }) => {
      if (allowedRoles.includes(userRole)) {
        return (
          <AppLayout userRole={userRole}>
            <Component {...props} />
          </AppLayout>
        );
      }
      return <Redirect to={DEFAULT_REDIRECT[userRole]} />;
    };

    return (
      <Route
        {...rest}
        render={(props) =>
          api.authToken ? (
            getRoleComponent({ userRole: api.userRole, props })
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
);

export default PrivateRoute;
