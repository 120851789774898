import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withSubMenuLayout } from 'modules/core/layouts';
import { UNITS, PRODUCTIVA, MANAGEMENT } from 'modules/core/constants';
import StockHome from './Stock/StockHome';
import {
  Bajas,
  Movimientos,
  RecordHistory,
} from 'modules/management/productiva/events';

const submenuOptions = [
  {
    title: 'Stock',
    url: `/${UNITS.NAME_SPACE}/stock`,
    exact: false,
  },
  {
    title: 'Movimientos',
    url: `/${UNITS.NAME_SPACE}/${PRODUCTIVA.MOVIMIENTO}`,
    exact: false,
  },
  {
    title: 'Bajas',
    url: `/${UNITS.NAME_SPACE}/${PRODUCTIVA.BAJA}`,
    exact: false,
  },
];
const eventType = PRODUCTIVA.EVENT_TYPES_UNIT_ROUTES.join('|');
const withSubLayout = withSubMenuLayout(submenuOptions);

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/${UNITS.NAME_SPACE}/stock`}
        component={withSubLayout(StockHome)}
      />
      <Route
        exact
        path={`/${UNITS.NAME_SPACE}/${PRODUCTIVA.MOVIMIENTO}`}
        component={withSubLayout(Movimientos)}
      />
      <Route
        exact
        path={`/${UNITS.NAME_SPACE}/${PRODUCTIVA.BAJA}`}
        component={withSubLayout(Bajas)}
      />
      <Route
        exact
        path={`/${UNITS.NAME_SPACE}/:eventType(${eventType})/${MANAGEMENT.HISTORIAL_NAME_SPACE}`}
        component={withSubLayout(RecordHistory)}
      />
      <Redirect to={`/${UNITS.NAME_SPACE}/stock`} />
    </Switch>
  );
};

export default Routes;
